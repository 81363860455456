import { Controller} from "@hotwired/stimulus"
import { delegate, abnegate } from "jquery-events-to-dom-events"

export default class extends Controller {

  static values = {
    format: { type: String, default: 'dd/mm/yyyy' },
    week_start: { type: Number, default: 1 }
  }

  connect() {

    $(this.element).datepicker(
    {
      format: this.formatValue,
      weekStart: 1,
      startDate: "-3m",
      todayHighlight: true,
      language: "nl"
    });
  }

  disconnect(){
  }
}


